.form-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
}

.form-field {
  margin-bottom: 15px;
}

.form-labell {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  color: #000000;
  text-align: center;
  text-transform: uppercase; /* This will make all letters capital */
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.form-link {
  display: block;
  color: #2959a5;
  margin-top: 5px;
  text-decoration: none;
  cursor: pointer;
}
.form-link:hover {
  text-decoration: underline;
}

.form-button {
  background-color: #ef3e6d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: block;
  margin: auto;
}

.date-picker-container {
  width: 100%;
}

.text-button {
  display: block;
  color: #2959a5;

  text-decoration: none;
  font-weight: 600;
  padding: 0;
  border: 0;
  cursor: pointer;
}
.text-button:hover {
  text-underline-offset: 2px;
  text-decoration: underline;
}
.error-message{
  color: red !important;
  font-size: 12px;
  margin-top: 0 !important;
  padding: 0 !important;
}