/* #285915 */
/* #ef3e6d */
/* #ff0000 */


.main-admin-dashboard-container{
      background-color: #007bff; /* Blue background */
      color: black; /* White text color */
}

.admin-dashboard-title{
      margin-top: 20px;
}
.admin-dashboard-container{
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      padding: 16px;
      /* flex-direction: column; */
      position: relative;
      align-items: start;
}

.admin-dashboard-section{
      display: grid;
      /* flex:1; */
      /* grid-template-columns: repeat(2, 1fr); */
      /* grid-template-rows: repeat(2, 1fr); */
      gap: 16px;
      grid-template-columns: 1fr ; 
}


.down {
      width: 12px;
      height: 12px;
      clip-path: polygon(100% 0%, 0% 0%, 50% 100%);
      background-color: red;
  }
.up {
      width: 12px;
      height: 12px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: green;
  }

.card-list-item{
      display: flex;
      gap: 8px;
      font-size: 15px;
      padding: 8px 0;
      border-bottom: .5px solid #ccc;
  }
  
.card-list-item .serial,
.card-list-item .item-title{
      font-size: 18px;
}
.card-list-item .vendor-title{
      font-size: 16px;
}
.card-list-item .serial{
            color: #037dff;
            font-weight: 500;
      }
.total-sold{
            color: #5b5b5b;
      }

.sub-card-heading{
      font-size: 16px;
      color: #ef3e6d;
}
  
/* Small devices */
@media (min-width: 640px) {
      /* Your styles for small devices and up */
      .admin-dashboard-container{
            grid-template-columns: 1fr;     
      }
   
      
  }
  
  /* Medium devices */
  @media (min-width: 768px) {
 
      .admin-dashboard-container{
            grid-template-columns: repeat(2, 1fr);
      }
      .admin-dashboard-section{
            grid-template-columns: repeat(2, 1fr);
      }
  }
  
  /* Large devices */
  @media (min-width: 1024px) {
      /* Your styles for large devices and up */
     
  }
  
  /* Extra Large devices */
  @media (min-width: 1280px) {
      /* Your styles for extra large devices and up */
      .admin-dashboard-section{
            grid-template-columns: repeat(2, 1fr);
      }
  }

/*  */


.text-green{
      color: #285915 !important;
}
.text-red{
      color: #ff0000;
}
.flex {
      display: flex;
}

.flex-column{
      flex-direction: column;
}
.flex-row{
      flex-direction: row;
}
.justify-center{
      justify-content: center;
}
.items-center{
      align-items: center;
}