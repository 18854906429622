@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("UberMoveMedium"),
    url("./assets/fonts/UberMoveMedium.otf") format("opentype");
}
@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("UberMoveBold"),
    url("./assets/fonts/UberMoveBold.otf") format("opentype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html * {
  font-family: "Medium" !important;
}


.see-details-btn{
  color: #2959a5 !important;
  text-wrap: nowrap !important;
  text-align: center;
}
.see-details-btn:hover{
  text-decoration: underline;
}
.download-rpt-btn{
  color: #10213d !important;
  text-wrap: nowrap !important;
  text-decoration: underline;
}
.download-rpt-btn:hover{
  color: #2959a5 !important;
}
.download-full-rpt-btn{
  color:white !important;
  background-color: #ef3e6d !important;
  border:2px solid #ef3e6d;
  text-decoration: none !important;
  padding: 8px 18px;
  transition: all 150ms;
}
.download-full-rpt-btn:hover{
  background-color:white !important;
  color: #ef3e6d !important;
  
}