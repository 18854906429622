.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.sigCanvas {
  border: 2px solid #000;
}

.button-container {
  margin-top: 10px;
}

.clear-button,
.save-button {
  padding: 8px 16px;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
}

.clear-button {
  background-color: #EF3E6D;
  color: #fff;
  border: none;
}

.save-button {
  background-color: #007bff;
  color: #fff;
  border: none;
}
